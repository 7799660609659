function roleColour(role) {
  if (
    role === "Attacker" ||
    role === "Attacker: Melee" ||
    role === "Attacker: Ranged"
  ) {
    // return "green accent-4" // AAA
    return "#00C853" // AAA
  }
  if (role === "Defender") {
    // return "pink accent-2" // AA
    return "#FF4081" // AA
  }
  // return "blue-grey lighten-1" // AA
  return "#78909C" // AA
}

export { roleColour }
